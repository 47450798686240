import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import type { InferProps, Validator } from 'prop-types';

import classNames from 'classnames';

import CarouselProgressControls, { CarouselControlsProgressPropTypes } from './carousel-progress-controls';

export enum CarouselProgressControlPosition {
	ABOVE = 'above',
	BELOW = 'below',
}
function isCarouselProgressControlPosition(val: unknown): val is CarouselProgressControlPosition {
	const validValues = Object.values(CarouselProgressControlPosition);
	return (validValues as unknown[]).includes(val);
}

export enum CarouselProgressSpeed {
	NORMAL = 'normal',
	FAST = 'fast',
}
export function isCarouselProgressSpeed(val: unknown): val is CarouselProgressSpeed {
	const validValues = Object.values(CarouselProgressSpeed);
	return (validValues as unknown[]).includes(val);
}

/**
 * The speed for each `CarouselProgressSpeed` value, in seconds
 */
const durations: Record<CarouselProgressSpeed, number> = {
	[CarouselProgressSpeed.NORMAL]: 10,
	[CarouselProgressSpeed.FAST]: 7,
};

const propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element.isRequired,
		PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
	]),
	className: PropTypes.string,
	controlsClassName: PropTypes.string,
	itemsClassName: PropTypes.string,

	controlPosition: function (props, propName, componentName) {
		const value = props[propName] as unknown;

		if (isCarouselProgressControlPosition(value)) {
			return null;
		} else if (value === null) {
			return null;
		} else if (typeof value === 'undefined') {
			return null;
		} else {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}: unrecognised \`CarouselProgressControlPosition\` value ${value}`);
		}
	} as Validator<CarouselProgressControlPosition | null | undefined>,

	initialSlideIndex: PropTypes.number,

	speed: function (props, propName, componentName) {
		const value = props[propName] as unknown;

		if (!isCarouselProgressSpeed(value)) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Unrecognised speed value ${value}`);
		}

		return null;
	} as Validator<CarouselProgressSpeed>,

	// Type assertion used here because I don't think there's a better way to add type definitions for a function prop
	onSlideChange: PropTypes.func.isRequired as Validator<(slideIndex: number) => void>,

	paused: PropTypes.bool,
	pauseOnHover: PropTypes.bool,
};
type CarouselProgressProps = InferProps<typeof propTypes>;

const defaultProps = {
	controlPosition: CarouselProgressControlPosition.BELOW,
	initialSlideIndex: 0,
	speed: CarouselProgressSpeed.NORMAL,

	paused: false,
	pauseOnHover: true,
};

/**
 * This component expects each carousel slide to be a direct child. For example:
 *
 * ```jsx
 * <CarouselProgress
 * 	// define props
 * >
 * 	{props.items.map((item, i) => (
 * 		<div key={i}>Slide {i}</div>
 * 	)}
 * </CarouselProgress>
 * ```
 */
const CarouselProgress = Object.assign(
	function CarouselProgress(props: CarouselProgressProps) {
		const children = Array.isArray(props.children) ? props.children : [props.children];

		const speed = props.speed ?? defaultProps.speed;
		const duration = durations[speed];

		const [currentSlideIndex, setCurrentSlideIndex] = useState(props.initialSlideIndex ?? defaultProps.initialSlideIndex);

		const [queuedSlideTransition, setQueuedSlideTransition] = useState<number | null>(null);
		const [isQueuedUserInteraction, setIsQueuedUserInteraction] = useState(false);

		const [isPaused, setIsPaused] = useState(false);
		const [isCancelled, setIsCancelled] = useState(false);

		function cancel() { setIsCancelled(true); }

		const [hasEnteredViewport, setHasEnteredViewport] = useState(false);

		const [isPausedDueToHover, setIsPausedDueToHover] = useState(false);
		const [isPausedDueToControlsHover, setIsPausedDueToControlsHover] = useState(false);
		const [isPausedDueToFocus, setIsPausedDueToFocus] = useState(false);
		const [isPausedDueToTouch, setIsPausedDueToTouch] = useState(false);

		enum PauseReason {
			HOVER,
			CONTROLS_HOVER,
			FOCUS,
			TOUCH,
		}

		function pause(reason: PauseReason) {
			switch (reason) {
				case PauseReason.HOVER:
					setIsPausedDueToHover(true);
					break;
				case PauseReason.CONTROLS_HOVER:
					setIsPausedDueToControlsHover(true);
					break;
				case PauseReason.FOCUS:
					setIsPausedDueToFocus(true);
					break;
				case PauseReason.TOUCH:
					setIsPausedDueToTouch(true);
					break;
			}
		}

		function resume(reason: PauseReason) {
			switch (reason) {
				case PauseReason.HOVER:
					setIsPausedDueToHover(false);
					break;
				case PauseReason.CONTROLS_HOVER:
					setIsPausedDueToControlsHover(false);
					break;
				case PauseReason.FOCUS:
					setIsPausedDueToFocus(false);
					break;
				case PauseReason.TOUCH:
					setIsPausedDueToTouch(false);
					break;
			}
		}

		// Wait until it first enters the viewport before beginning the countdown
		const componentRef = useRef<HTMLDivElement>(null);
		useEffect(() => {
			// `IntersectionObserver` monitors for intersections with the viewport by default
			const observer = new IntersectionObserver((entries) => {
				for (const entry of entries) {
					if (entry.isIntersecting) {
						setHasEnteredViewport(true);
						return;
					}
				}
			});

			const componentEl = componentRef.current;

			if (componentEl && (hasEnteredViewport === false)) {
				observer.observe(componentEl);
			}

			return () => {
				observer.disconnect();
			};
		}, [hasEnteredViewport, componentRef]);

		// Update paused state based on multiple reasons for pausing
		const pauseOnHover = props.pauseOnHover ?? defaultProps.pauseOnHover;
		const isPausedDueToProps = props.paused ?? defaultProps.paused;
		useEffect(() => {
			const shouldBePaused = (pauseOnHover && isPausedDueToHover) ||
				isPausedDueToControlsHover ||
				isPausedDueToFocus ||
				isPausedDueToTouch ||
				isPausedDueToProps
			;

			setIsPaused(shouldBePaused);
		}, [
			pauseOnHover,
			isPausedDueToHover,
			isPausedDueToControlsHover,
			isPausedDueToFocus,
			isPausedDueToTouch,
			isPausedDueToProps,
		]);

		/**
		 * If the carousel is paused, queue the slide transition for later
		*/
		const onSlideChangeWithQueue = useCallback(
			function onSlideChangeWithQueue(slideIndex: number, isUserInteraction: boolean): void {
				setIsQueuedUserInteraction(isUserInteraction);
				setQueuedSlideTransition(slideIndex);
			},
			[],
		);

		const { onSlideChange } = props;
		// Run queued slide change transition when coming unpaused
		useEffect(() => {
			if (
				(queuedSlideTransition !== null) &&
				(isPaused === false || isQueuedUserInteraction === true)
			) {
				setCurrentSlideIndex(queuedSlideTransition);
				setIsQueuedUserInteraction(false);
				onSlideChange(queuedSlideTransition);
			}
		}, [
			queuedSlideTransition,
			isPaused,
			isQueuedUserInteraction,
			onSlideChange,
		]);

		if (children.length === 0) {
			return null;
		}

		function renderControls({ begin }: Pick<CarouselControlsProgressPropTypes, 'begin'>) {
			return (
				<CarouselProgressControls
					className={classNames(props.controlsClassName, {
						['carousel-progress__controls--paused']: isPaused && !isCancelled,
					})}
					onMouseEnter={() => pause(PauseReason.CONTROLS_HOVER)}
					onMouseLeave={() => resume(PauseReason.CONTROLS_HOVER)}

					begin={begin}

					numSlides={children.length}
					cancelled={isCancelled}
					onSlideChange={onSlideChangeWithQueue}

					currentSlideIndex={currentSlideIndex}
					duration={duration}
				/>
			);
		}

		return (
			<div
				ref={componentRef}

				className={classNames(props.className)}
				onMouseEnter={() => pause(PauseReason.HOVER)}
				onMouseLeave={() => resume(PauseReason.HOVER)}
				onFocus={() => pause(PauseReason.FOCUS)}
				onBlur={() => resume(PauseReason.FOCUS)}
				onTouchStart={() => pause(PauseReason.TOUCH)}
				onTouchEnd={() => resume(PauseReason.TOUCH)}
				onClick={cancel}
				onKeyDown={cancel}
			>
				{
					((props.controlPosition ?? defaultProps.controlPosition) === CarouselProgressControlPosition.ABOVE) &&
					renderControls({
						begin: hasEnteredViewport,
					})
				}
				<div
					className={classNames(props.itemsClassName)}
					aria-live={isCancelled ? 'polite' : 'off'}
				>
					{props.children}
				</div>
				{
					((props.controlPosition ?? defaultProps.controlPosition) === CarouselProgressControlPosition.BELOW) &&
					renderControls({
						begin: hasEnteredViewport,
					})
				}
			</div>
		);
	},

	{
		propTypes,
		defaultProps,
	},
);

export default CarouselProgress;
