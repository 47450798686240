import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import getAcfBlockProps from 'components/prop-types/acf-block';

import Image, { Crop } from './image';

import Overlay from './overlay';
import VimeoPlayer from '@vimeo/player';

import CarouselProgress from './carousel-progress';

import { trackEvent } from '../utils/gtm/index';

const propTypes = {
	...getAcfBlockProps(
		{
			brands_tagline: PropTypes.string,
			brands: PropTypes.arrayOf(PropTypes.shape({
				image: PropTypes.shape({
					url: PropTypes.string.isRequired,
					alt: PropTypes.string,

					width: PropTypes.number,
					height: PropTypes.number,
				}).isRequired,
			}).isRequired),

			testimonials: PropTypes.arrayOf(PropTypes.shape({
				tagline: PropTypes.string,

				text: PropTypes.string.isRequired,
				citation: PropTypes.string,
				is_quote: PropTypes.bool.isRequired,

				image: PropTypes.shape({
					url: PropTypes.string.isRequired,
					alt: PropTypes.string,

					width: PropTypes.number,
					height: PropTypes.number,

					sizes: PropTypes.shape({
						medium_large: PropTypes.string.isRequired,
						['medium_large-width']: PropTypes.number.isRequired,
						['medium_large-height']: PropTypes.number.isRequired,
					}),
				}).isRequired,
				video: PropTypes.string,
			}).isRequired).isRequired,
		}
	),
};
type TestimonialCarouselPropTypes = InferProps<typeof propTypes>;

const TestimonialCarousel = Object.assign(
	function TestimonialCarousel(props: TestimonialCarouselPropTypes) {
		const content = props.block?.content;

		const idPrefix = `id${Math.floor(Math.random() * 1000).toString(16)}-testimonial-carousel`;

		const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

		const [currentVideo, setCurrentVideo] = useState<number | null>(null);
		const [vimeoPlayer, setVimeoPlayer] = useState<VimeoPlayer | null>(null);

		function showVideoForSlide(index: number) {
			return function showVideo(e: React.MouseEvent<HTMLButtonElement>) {
				setCurrentVideo(index);
				trackEvent({
					category: 'Testimonial carousel',
					action: 'Video click',
					label: content?.testimonials[index].video ?? undefined,
				});
			};
		}

		// The Vimeo iframe doesn't exist until `currentVideo` is set, so don't initialise the Vimeo player until then
		useEffect(
			() => {
				if (currentVideo !== null) {
					const player = new VimeoPlayer(`${idPrefix}-video-overlay-${currentVideo}`);
					player.play();
					setVimeoPlayer(player);
				} else {
					vimeoPlayer?.pause();
					setVimeoPlayer(null);
				}
			},
			[currentVideo, vimeoPlayer, idPrefix]
		);

		function closeModal() {
			setCurrentVideo(null);
		}

		if (!content) {
			return null;
		}

		return (
			<div className="container">
				{
					(content.brands_tagline || content.brands) &&
					<div className="testimonial-carousel__brands">
						{
							content.brands_tagline &&
							<span className="testimonial-carousel__brands__tagline">{content.brands_tagline}</span>
						}
						{
							content.brands &&
							<ul className="testimonial-carousel__brands__list">
								{content.brands.map((item, i) => (
									<li
										key={i}
										className="testimonial-carousel__brand"
									>
										<div className="testimonial-carousel__brand__figure">
											<Image
												className="testimonial-carousel__brand__image"
												lazy={true}
												block={{
													content: {
														...item.image,
													},
												}}
											/>
										</div>
									</li>
								))}
							</ul>
						}
					</div>
				}
				<CarouselProgress
					className="testimonial-carousel__viewport"
					itemsClassName="testimonial-carousel__testimonials"
					controlsClassName="testimonial-carousel__controls"

					onSlideChange={setCurrentSlideIndex}
				>
					{content.testimonials.map((item, i) => (
						<div
							key={i}
							aria-hidden={currentSlideIndex !== i}
							className="testimonial-carousel__testimonial"
						>
							<div className="testimonial-carousel__testimonial__body">
								{
									item.tagline &&
									<span className="testimonial-carousel__testimonial__tagline">{item.tagline}</span>
								}
								{
									item.is_quote &&
									<blockquote className="testimonial-carousel__testimonial__quote">
										<div className="testimonial-carousel__testimonial__quote__text">
											{item.text}
										</div>
										{
											item.citation &&
											<cite className="testimonial-carousel__testimonial__citation">{item.citation}</cite>
										}
									</blockquote>
								}
								{
									!item.is_quote &&
									<div className="testimonial-carousel__testimonial__content">
										<div className="testimonial-carousel__testimonial__content__text">
											{item.text}
										</div>
										{
											item.citation &&
											<span className="testimonial-carousel__testimonial__citation">{item.citation}</span>
										}
									</div>
								}
							</div>
							{
								item.video &&
								<figure className="testimonial-carousel__testimonial__figure">
									<button
										type="button"
										className="testimonial-carousel__testimonial__video-button"
										onClick={showVideoForSlide(i)}
									>
										<Image
											className="testimonial-carousel__testimonial__image"
											lazy={true}
											block={{
												content: {
													...item.image,
													crop: Crop.MEDIUM_LARGE,
												},
											}}
										/>
									</button>
								</figure>
							}
							{
								(!item.video) &&
								<figure className="testimonial-carousel__testimonial__figure">
									<Image
										className="testimonial-carousel__testimonial__image"
										lazy={true}
										block={{
											content: {
												...item.image,
												crop: Crop.MEDIUM_LARGE,
											},
										}}
									/>
								</figure>
							}
						</div>
					))}
				</CarouselProgress>

				{/* Render Overlays outside of viewport, to avoid stacking conflicts */}
				{content.testimonials.filter(
					(item): item is typeof item & { video: NonNullable<(typeof item)['video']> } => !!item.video)
				.map((item, i) => (
					<Overlay
						key={i}
						show={currentVideo === i}
						classes=""
						video={true}
						handleClose={closeModal}
						lightModal={false}
					>
						{
							(currentVideo === i) &&
							<iframe
								id={`${idPrefix}-video-overlay-${i}`}
								src={item.video}
								frameBorder="0"
								allowFullScreen
								allow="autoplay"
							></iframe>
						}
					</Overlay>
				))}
			</div>
		);
	},

	{ propTypes },
);

export default TestimonialCarousel;
