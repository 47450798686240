import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import classNames from 'classnames';

const propTypes = {
	className: PropTypes.string,

	numSlides: PropTypes.number.isRequired,
	currentSlideIndex: PropTypes.number.isRequired,

	duration: PropTypes.number.isRequired, // seconds
	begin: PropTypes.bool,
	cancelled: PropTypes.bool,

	// Type assertion used here because I don't think there's a better way to add type definitions for a function prop
	onSlideChange: PropTypes.func.isRequired as PropTypes.Validator<(slideIndex: number, isUserInteraction: boolean) => void>,

	onMouseEnter: PropTypes.func as PropTypes.Validator<React.MouseEventHandler>,
	onMouseLeave: PropTypes.func as PropTypes.Validator<React.MouseEventHandler>,
};
export type CarouselControlsProgressPropTypes = InferProps<typeof propTypes>;

const defaultProps = {
	begin: true,
	cancelled: false,
};

const CarouselControlsProgress = Object.assign(
	function (props: CarouselControlsProgressPropTypes) {
		const begin = props.begin ?? defaultProps.begin;
		const cancelled = props.cancelled ?? defaultProps.cancelled;

		const [progressAnim, setProgressAnim] = useState<Animation | null>(null);

		const progressRefs = useMemo(() => {
			const progressElements: React.RefObject<HTMLElement>[] = [];
			for (let i = 0; i < props.numSlides; i++) {
				progressElements[i] = createRef<HTMLElement>();
			}

			return progressElements;
		}, [props.numSlides]);

		const { numSlides, onSlideChange } = props;
		const setSlide = useCallback(
			function setSlide(slideIndex: number, isUserInteraction: boolean = false) {
				while (slideIndex < 0) {
					slideIndex += numSlides;
				}
				slideIndex %= numSlides;

				onSlideChange(slideIndex, isUserInteraction);
			},
			[
				numSlides,
				onSlideChange,
			],
		);

		// Update current slide and set animation in motion
		const { currentSlideIndex } = props;
		useEffect(() => {
			const currentProgressRef = progressRefs[currentSlideIndex];
			let animation: Animation | null = null;

			function nextSlide() {
				setSlide(currentSlideIndex + 1);
			}

			if (begin && !cancelled) {
				const progressKeyframes: Keyframe[] = [{ width: 0 }, {}];
				animation = currentProgressRef.current?.animate(progressKeyframes, props.duration * 1000) || null;

				animation?.addEventListener('finish', nextSlide);
				setProgressAnim(animation);
			}

			return () => {
				animation?.removeEventListener('finish', nextSlide);
				animation?.cancel();
				setProgressAnim(null);
			};
		}, [
			currentSlideIndex,
			props.duration,
			begin,
			cancelled,

			setSlide,
			progressRefs,
		]);

		// Cancel animation
		useEffect(() => {
			if (props.cancelled) {
				progressAnim?.cancel();
			}
		}, [props.cancelled, progressAnim]);

		if (props.numSlides < 2) {
			return null;
		}

		return (
			<div
				className={classNames('carousel-progress__controls', props.className)}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
			>
				{
					(new Array(props.numSlides).fill(null)).map((val, i) => (
						<button
							key={i}

							type="button"
							className="carousel-progress__controls__item"
							aria-current={currentSlideIndex === i}
							onClick={() => setSlide(i, true)}
							aria-label={`Slide ${i + 1}`}
						>
							<span className="carousel-progress__controls__item__progress-wrap">
								<span
									className="carousel-progress__controls__item__progress"
									ref={progressRefs[i]}
								></span>
							</span>
							<span className="carousel-progress__controls__item__paused"></span>
						</button>
					))
				}
			</div>
		);
	},

	{
		propTypes,
		defaultProps,
	},
);

export default CarouselControlsProgress;
